body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', serif;
  background-image: url('./background.png');
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  font-weight: 200;
}
