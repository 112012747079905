@import url(https://fonts.googleapis.com/css?family=Antic+Slab);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300);
@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300);

.App {
  text-align: center;
  font-family: 'Roboto', serif;
}


body {
  background-color: #252525 !important;
}

body, .wrapper {
  min-height:100vh;
}

.center {
  margin:0 auto;
  float:none;
}

.h1-title
{
  font-family: 'Roboto', serif;
  padding-bottom: 10px;
  font-size: 3.5em !important;
  font-weight: 100;


}
